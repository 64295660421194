
@tailwind base;
@tailwind components;
@tailwind utilities;
@media print {
    .page-break {
      break-before: page; /* Hoặc page-break-before: always cho trình duyệt cũ */
    }
  }
  
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* App.css */
.viewer-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
}

.rpv-core__viewer {
    border-radius: 10px; /* Default border-radius */
    transition: border-radius 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .viewer-container {
        padding: 10px;
    }

    .rpv-core__viewer {
        border-radius: 5px;
    }
}

@media (max-width: 480px) {
    .viewer-container {
        padding: 5px;
    }

    .rpv-core__viewer {
        border-radius: 0;
    }
}
